import styles from './styles.module.css';
import classNames from 'classnames';
import React, { CSSProperties, ReactNode, useState, useRef } from 'react';
import { CustomButton } from 'src/components/formComponents/customButton';
import { SearchIcon } from 'src/icons/search';
import { AnchorIcon } from 'src/icons/anchor';
import { LinkHelper } from 'src/utils/link-helper';

interface TitleProps extends TitleSearchProps {
	text?: string | null;
	id?: string;
	withSeparator?: boolean;
	style?: CSSProperties;
	titleStyle?: CSSProperties;
	className?: string;
}

interface TitleSearchProps {
	onSearch?: (searchTerm: string) => any;
}

const Search = (props: TitleSearchProps) => {
	const [searchTerm, setSearchTerm] = useState('');

	const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.keyCode === 13) {
			props.onSearch && props.onSearch(searchTerm);
		}
	};

	return (
		<div className={styles.sectionContainerTitleSearchTab}>
			<SearchIcon color="#52D07A" />
			<input
				type="text"
				placeholder="Search"
				className={styles.sectionContainerTitleInput}
				onKeyUp={onKeyUp}
				value={searchTerm}
				onChange={(event) => setSearchTerm(event.target.value)}
			/>
		</div>
	);
};

export const SectionContainerTitle = (props: TitleProps) => {
	const { text, withSeparator, onSearch, style, titleStyle, className, id } = props;
	const inputRef = useRef<HTMLInputElement>(null);

	const containerClass = classNames(
		styles.sectionContainerTitleContainer,
		{ [styles.sectionContainerTitleWithBorder]: withSeparator },
		className
	);

	const onCopyLink = (ev: React.MouseEvent) => {
		if (inputRef) {
			inputRef.current?.select && inputRef.current.select();
			document?.execCommand && document?.execCommand('copy');
			ev.stopPropagation();
		}
	};

	const encodedId = id ? LinkHelper.parseInternalLink(id) : undefined;

	return (
		<div className={containerClass} style={style} id={id}>
			<h2 style={titleStyle} className={styles.sectionContainerTitleTitle}>
				{encodedId && typeof window !== 'undefined' && (
					<div className={styles.anchorContainer} onClick={onCopyLink}>
						<AnchorIcon />
						<input
							className={styles.copyInput}
							type="text"
							value={`${window.location.origin}${window.location.pathname}#${encodedId}`}
							ref={inputRef}
							readOnly
						></input>
					</div>
				)}
				{text}
			</h2>
			{onSearch && <Search onSearch={onSearch} />}
		</div>
	);
};

interface Props {
	children?: ReactNode;
	isGrey?: boolean;
	withBorderTop?: boolean;
	style?: CSSProperties;
	onLoadMore?: () => any;
	className?: string;
}

export const SectionContainer = (props: Props) => {
	const { children, isGrey, withBorderTop, style, onLoadMore, className } = props;

	const containerClass = classNames(
		styles.container,
		{ [styles.withBorderTop]: withBorderTop },
		{ [styles.grey]: isGrey },
		className ? className : undefined
	);

	const loadMoreSection = (
		<div className={styles.loadMoreContainer}>
			<CustomButton
				content="Load More"
				variant="secondary"
				onClick={onLoadMore}
				inlineStyle={{ minWidth: '183px', justifyContent: 'center' }}
			/>
		</div>
	);

	return (
		<div className={containerClass} style={style}>
			<div className={styles.innerContainer}>
				{children}
				{onLoadMore && loadMoreSection}
			</div>
		</div>
	);
};

interface FlexContainerProps {
	children: ReactNode;
	style?: CSSProperties;
	noSpaceBetween?: boolean;
	className?: string;
}

export const SectionContainerFlex = (props: FlexContainerProps) => {
	const { children, style, noSpaceBetween, className } = props;
	const containerFlexClass = classNames([
		{ [styles.containerFlex]: !noSpaceBetween } /* yes space between */,
		{ [styles.containerFlexNoSpaceBetween]: noSpaceBetween },
		className,
	]);

	return (
		<div className={containerFlexClass} style={style}>
			{children}
		</div>
	);
};

interface GridContainerProps {
	columns: '1' | '2' | '3';
	children: ReactNode;
	style?: CSSProperties;
	className?: string;
}

export const SectionContainerGrid = (props: GridContainerProps) => {
	const { columns, children, style, className } = props;
	const containerClass = classNames([
		className,
		{ [styles.grid1]: columns === '1' },
		{ [styles.grid2]: columns === '2' },
		{ [styles.grid3]: columns === '3' },
	]);

	return (
		<div className={containerClass} style={style}>
			{children}
		</div>
	);
};
